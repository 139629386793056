import { FunctionComponent, useState } from "react";

interface ImageHoverProps {
  mainImage: string;
  mainImageWebp: string;
  hoverImage: string;
  hoverImageWebp: string;
  alt: string;
}

const ImageHover: FunctionComponent<ImageHoverProps> = ({
  mainImage,
  mainImageWebp,
  hoverImage,
  hoverImageWebp,
  alt,
}: ImageHoverProps) => {
  const [isHover, setIsHover] = useState<boolean>(false);
  const onHoverHandler = (e: any) => {
    if (e.type === "mouseenter") {
      setIsHover(true);
    } else {
      setIsHover(false);
    }
  };
  return (
    <div
      className="product__item__image"
      onMouseEnter={onHoverHandler}
      onMouseLeave={onHoverHandler}
    >
      {isHover ? (
        <picture>
          <source srcSet={hoverImageWebp} type="image/webp" />
          <source srcSet={hoverImage} type="image/png" />
          <img src={hoverImage} alt={alt} />
        </picture>
      ) : (
        <picture>
          <source srcSet={mainImageWebp} type="image/webp" />
          <source srcSet={mainImage} type="image/png" />
          <img src={mainImage} alt={alt} />
        </picture>
      )}
    </div>
  );
};

export default ImageHover;
