import styled from "styled-components";

export const PostPage = styled.div`
  line-height: 1.45;
  padding: 30px 0;
  width: 90%;

  margin: 0 auto;
  .backHome {
    display: block;
    font-size: 24px;
    margin-bottom: 20px;
  }

  * {
    text-align: justify;
  }

  .intro {
    font-size: 24px;
    margin-bottom: 50px;
  }
`;

export const PostHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 25px;
  flex-direction: column;
  h1 {
    color: #4f2410;
    margin-bottom: 20px;
  }
  @media screen and (min-width: 1024px) {
    flex-direction: row;
    h1 {
      width: 55%;
    }
  }
`;

export const PostCover = styled.div`
  img,
  picture {
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  @media screen and (min-width: 1024px) {
    width: 42.5%;
  }
`;
export const PostParagraph = styled.div`
  width: 100%;
  margin: 0 auto;
  margin-bottom: 50px;
  h2 {
    font-size: 30px;
    color: #4f2410;
    margin-bottom: 10px;
  }
  p {
    font-size: 22px;
  }
  @media screen and (min-width: 768px) {
    width: 80%;
  }
`;

export const PostParagraphPhoto = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  picture,
  img {
    height: 250px;
    width: auto;
    object-fit: contain;
  }
`;

export const PostCTA = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: center;
`;

export const PostCTAButton = styled.a`
  background: #4f2410;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  overflow: hidden;
  max-width: 300px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
  }
  p {
    width: 300px;
    color: white;
    text-align: center;
    font-size: 24px;
    line-height: 40px;
    margin-top: -6px;
  }
`;

export const PostReviewDiv = styled.div`
  margin: 20px 0;
  background: #e9dccd;
  padding: 10px 16px;
  border-radius: 10px;
  .customer-review-heading {
    text-align: center;
    margin-bottom: 6px;
  }
  .customer-review-main {
    font-size: 26px;
    margin-bottom: 12px;
  }
  .customer-review-name {
    text-decoration: italic;
    text-align: center;
  }
`;

export const RelatedPostDiv = styled.div`
  margin-top: 80px;
  display: flex;
  flex-wrap: wrap;
  h2 {
    width: 100%;
    font-size: 30px;
  }
`;
