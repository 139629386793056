import { FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/header";
import * as Styled from "./styles";
import fallFestPhoto from "./frankfort fall fest.jpeg";
import etsyPhoto from "./bohanan-woodcraft-etsy.png";
import paintedTree from "./painted-tree.png";
import lambsFarmFest from "./lambs-farms-summertime.jpeg";

const LocationPage: FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bohanan Woodcraft - Location</title>
        <link rel="canonical" href={`https://bohananwoodcraft.com/location`} />
        <meta
          name="description"
          content={`Bohanan Woodcraft is a small business based in Illinois. We specialize in handmade woodcraft, including kitchen ware, home decor, wood art. Our signature product is Bread bow knife and Cedar soap dish. We are also Etsy Star Seller, we are expanding to local store!!`}
        ></meta>
      </Helmet>
      <Header />
      <Styled.LocationPage>
        <h3>Where to buy our products?</h3>
        <p>
          We originally start with Etsy store. Within 6 months of shop launch,
          we made 1,000+ sales. We are thrilled!
          <br />
          To reach our customers, we are excited to announce that we will be
          participating in various popup stores, craft fair and local stores!
        </p>
        <Styled.LocationSection>
          <h4>Painted Tree in Bloomingdale, IL</h4>
          <Styled.LocationPageShopImage>
            <img src={paintedTree} alt="Bohanan woodcraft is in Painted Tree" />
          </Styled.LocationPageShopImage>
          <p>
            <p>Address: 368 W Army Trail Rd</p>
            <p>Bloomingdale, IL 60108</p>
            <p>Booth : H3</p>
          </p>
        </Styled.LocationSection>
        <Styled.LocationSection>
          <h4>Lambs Farm Summertime Craft Fest 2024 - August 10 - 11, 2024</h4>
          <Styled.LocationPageShopImage>
            <img
              src={lambsFarmFest}
              alt="Bohanan woodcraft is participating Lambs Farm Summertime craft fest"
            />
          </Styled.LocationPageShopImage>
          <p>
            <p>Address: Camp Big Timber</p>
            <p>7826, 37W955 Big Timber Rd</p>
            <p>Elgin, IL 60124</p>
            <p>Booth : TBC</p>
          </p>
        </Styled.LocationSection>
        <Styled.LocationSection>
          <h4>Frankfort Fall Fest - Aug 31 - Sept 2, 2024</h4>
          <Styled.LocationPageShopImage>
            <img
              src={fallFestPhoto}
              alt="Bohanan woodcraft is participating Frankfort fall fest"
            />
          </Styled.LocationPageShopImage>
          <p>
            <p>Address: 123 Kansas St,</p>
            <p>Frankfort, IL 60423</p>
            <p>Booth : TBC</p>
          </p>
        </Styled.LocationSection>

        <Styled.LocationSection>
          <h5>
            Out of state? We offer free shipping for most of our products! Shop
            on Etsy!
          </h5>
          <Styled.LocationPageShopImage>
            <img src={etsyPhoto} alt="Bohanan woodcraft etsy shop" />
          </Styled.LocationPageShopImage>
          <a
            href="https://www.etsy.com/shop/BohananWoodcraft"
            target="_blank"
            rel="noreferrer"
          >
            Shop here
          </a>
        </Styled.LocationSection>
      </Styled.LocationPage>
    </>
  );
};

export default LocationPage;
