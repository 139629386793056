import reviewData from "../assets/data/reviews.json";

export type Review = {
  id: number;
  rating: number;
  text: string;
};

const getReviews = () => {
  let reviews: Review[] = [];
  reviewData.slice(0, 9).forEach((data) => {
    reviews.push({
      id: data.transaction_id,
      rating: data.rating,
      text: data.review,
    });
  });
  return reviews;
};

export default getReviews;
