import { FunctionComponent, useState, useEffect } from "react";
import { useParams } from "react-router";
import { Helmet } from "react-helmet";

import productData, { Product, ProductData } from "./productData";
import ImageHover from "../../components/image-hover";
import Header from "../../components/header";

const ProductPage: FunctionComponent = () => {
  const params = useParams();
  const [productName, setProductName] = useState<string | undefined>(undefined);
  const [product, setProduct] = useState<Product | undefined>(undefined);
  useEffect(() => {
    if (!params) return;
    const { productName } = params;
    if (!productName) return;
    setProductName(productName);
    const allProducts = productData as ProductData;
    setProduct(allProducts[productName]);
  }, [params]);

  if (!productName || !product) return <></>;
  return (
    <>
      <Header />

      <div className="product-page">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{product.productTitleLong}</title>
          <link
            rel="canonical"
            href={`https://bohananwoodcraft.com/products/${productName}`}
          />
          <meta
            name="description"
            content={`${product.description.join(",")}`}
          ></meta>
        </Helmet>
        <div className="product-page__top">
          <div className="product-page__image">
            <ImageHover
              mainImage={product.image.main}
              mainImageWebp={product.image.mainWebp}
              hoverImage={product.image.hover}
              hoverImageWebp={product.image.hoverWebp}
              alt="bohanan woodcraft bread bow knife"
            />
          </div>
          <div className="product-page__content">
            <h1>{product.productTitleLong}</h1>
            <a
              href={product.purchaseLink}
              target="_blank"
              className="product-page__buy-button"
              rel="noreferrer"
            >
              Buy on Etsy
            </a>
            {product.description.map((d: string) => (
              <p key={d}>{d}</p>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductPage;
