import { Link } from "react-router-dom";
import styled from "styled-components";

export const BlogThumbnailDiv = styled(Link)`
  width: 100%;
  padding: 20px;

  @media screen and (min-width: 768px) {
    width: 50%;
  }

  @media screen and (min-width: 1240px) {
    width: 33%;
  }
  display: flex;
  flex-direction: column;
`;

export const BlogThumbnailImgDiv = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const BlogThumbnailTitle = styled.p`
  font-size: 22px;
  margin-top: 10px;
`;
