import { useParams } from "react-router";
import * as Styled from "./styles";

const CareInstructions = () => {
  const params = useParams();
  return (
    <Styled.CareInstructionsPage>
      {params.productName === "bread-bow-knife" && (
        <>
          <h1>We hope you enjoy your new Bread Bow Knife!</h1>
          <ul>
            <li>We have finished the wood handle with food-safe mineral oil</li>
            <li>
              Apply more mineral oil as needed to keep the wood conditioned
            </li>
            <li>Hand wash only, Do not soak</li>
            <li>Careful! The blade is very sharp!</li>
          </ul>
          <h2>Tips on how to put on/off blade guard</h2>
          <video width="300" height="500" controls>
            <source src="/assets/videos/video_1.mp4" type="video/mp4" />
          </video>
          <p>
            Sometimes the blade guard might be too tight, you can cut a corner
            using scissors to create more space for blade to slide in
          </p>
          <video width="300" height="500" controls>
            <source src="/assets/videos/video_2.mp4" type="video/mp4" />
          </video>
        </>
      )}
      <a
        className="product__store-link"
        href="https://bohananwoodcraft.etsy.com"
        target="_blank"
        rel="noreferrer"
      >
        Explore our products
      </a>
    </Styled.CareInstructionsPage>
  );
};

export default CareInstructions;
