import styled from "styled-components";

export const CareInstructionsPage = styled.div`
  max-width: 740px;
  padding-top: 40px;
  margin-right: auto;
  margin-left: auto;
  padding-bottom: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: sans-serif;

  h1 {
    text-align: center;
    margin-bottom: 24px;
    font-size: 24px;
  }

  ul {
    font-size: 18px;
    li {
      padding: 8px 0;
    }
    font-family: sans-serif;
  }

  h2 {
    margin-top: 80px;
    margin-bottom: 12px;
    text-align: center;
    font-size: 16px;
  }

  video {
    margin-bottom: 30px;
  }
  p {
    font-size: 16px;
    margin-bottom: 12px;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: 36px;
    }
    ul {
      font-size: 24px;
    }
    h2 {
      margin-bottom: 24px;
      text-align: center;
      font-size: 28px;
    }
    p {
      font-size: 20px;
      margin-bottom: 30px;
      text-align: center;
    }
  }
`;
