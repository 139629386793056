import { Blog } from "../constants";
import birthMonthFlowers1 from "./photos/birth-month-flower/birth-month-flower-1.png";
import birthMonthFlowers2 from "./photos/birth-month-flower/birth-month-flower-2.png";
import birthMonthFlowers3 from "./photos/birth-month-flower/birth-month-flower-3.png";
import birthMonthFlowers4 from "./photos/birth-month-flower/birth-month-flower-4.png";
import birthMonthFlowersCover from "./photos/birth-month-flower/birth-month-flower-6.png";
import birthMonthFlowers1Webp from "./photos/birth-month-flower/birth-month-flower-1.webp";
import birthMonthFlowers2Webp from "./photos/birth-month-flower/birth-month-flower-2.webp";
import birthMonthFlowers3Webp from "./photos/birth-month-flower/birth-month-flower-3.webp";
import birthMonthFlowers4Webp from "./photos/birth-month-flower/birth-month-flower-4.webp";
import birthMonthFlowersCoverWebp from "./photos/birth-month-flower/birth-month-flower-6.webp";
import ringDish1Png from "./photos/ring-dish/ring-dish-combined.png";
import ringDish1Webp from "./photos/ring-dish/ring-dish-combined.webp";

import ringDishHexegon from "./photos/ring-dish/hexegon-wood-dish.png";
import ringDishHexegonWebp from "./photos/ring-dish/hexegon-wood-dish.webp";

import ringDishSquared from "./photos/ring-dish/square-wood-dish.png";
import ringDishSquaredWebp from "./photos/ring-dish/square-wood-dish.webp";

import ringDishHeartWalnut from "./photos/ring-dish/walnut-heart-ring-dish.png";
import ringDishHeartWalnutWebp from "./photos/ring-dish/walnut-heart-ring-dish.webp";

import beerOpenerMain from "./photos/bottle-opener/beer-opener.png";
import beerOpenerWebp from "./photos/bottle-opener/beer-opener.webp";
import beerOpenerColorPng from "./photos/bottle-opener/beer-opener-color.png";
import beerOpenerColorWebp from "./photos/bottle-opener/beer-opener-color.webp";
import beerOpenerWeddingPartyPng from "./photos/bottle-opener/beer-opener-wedding-party-gift.png";
import beerOpenerWeddingPartyWebp from "./photos/bottle-opener/beer-opener-wedding-party-gift.webp";

import cribbagePng from "./photos/game-board/cribbage-main.png";
import cribbageWebp from "./photos/game-board/cribbage-main.webp";
import cribbageDetailPng from "./photos/game-board/cribbage-game.png";
import cribbageDetailWebp from "./photos/game-board/cribbage-game.webp";
import cribbageWalnutPng from "./photos/game-board/cribbage-walnut.png";
import cribbageWalnutWebp from "./photos/game-board/cribbage-walnut.webp";
import cribbageBackPng from "./photos/game-board/cribbage-back.png";
import cribbageBackWebp from "./photos/game-board/cribbage-back.webp";

import soapDishMainPng from "./photos/soap-dish/soapdishMain.png";
import soapDishMainWebp from "./photos/soap-dish/soapdishMain.webp";
import soapDishReviewPng from "./photos/soap-dish/review_hh.png";
import soapDishReviewWebp from "./photos/soap-dish/review_hh.webp";
import soapDishFavouritePng from "./photos/soap-dish/design 3.png";
import soapDishFavouriteWebp from "./photos/soap-dish/design 3.webp";

const posts: Blog[] = [
  {
    cover: birthMonthFlowersCover,
    coverWebp: birthMonthFlowersCoverWebp,
    title: "Discover the Beauty and Meaning of Birth Month Flowers",
    slug: "discover-the-beauty-and-meaning-of-birth-month-flowers",
    intro:
      "Flowers have always been a timeless symbol of beauty, love, and emotion. Each flower carries its own unique meaning, and when it comes to celebrating special moments, few things can rival the charm of birth month flowers. These blossoms are not just beautiful; they hold significant meaning and offer a personalized touch that makes them the perfect gift for any occasion. Let's dive into the world of birth month flowers and explore why they are so special and unique.      ",
    paragraphs: [
      {
        subHeading: "What Are Birth Month Flowers?",
        text: "Birth month flowers are specific blooms associated with each month of the year. Much like birthstones, these flowers are believed to bring good fortune and reflect the characteristics of individuals born in that month. Each birth flower has a story to tell, adding a layer of depth and meaning to the simple act of gifting flowers.",
      },
      {
        subHeading: "",
        photo: {
          alt: "Birth month flower - January, February, March",
          caption: "Birth month flower - January, February, March",
          link: birthMonthFlowers1,
          linkWebp: birthMonthFlowers1Webp,
        },
        text: "",
      },
      {
        subHeading: "Birth Month Flowers - January - Snowdrop & Carnation",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Snowdrop, a symbol of hope and new beginnings, reminds us that even in the coldest times, beauty can bloom. Carnation, representing love, fascination, and distinction, perfectly captures the essence of those born in January",
      },
      {
        subHeading: "Birth Month Flowers - February - Iris, Violet & Primrose",

        text: "Violet, a symbol of faithfulness, wisdom, and hope, captures the spirit of February with its vibrant hues. Iris, embodying eloquence and deep sentiments, adds a touch of grace and beauty. Primrose, representing youth and everlasting love, reminds us of the fresh start each year brings",
      },
      {
        subHeading: "Birth Month Flowers - March - Daffodil & Cherry blossom",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Daffodil, a symbol of rebirth, new beginnings, and prosperity, perfectly captures the spirit of spring and renewal. Cherry blossom, representing beauty, love, and the fleeting nature of life, reminds us to cherish every moment. ",
      },
      {
        subHeading: "",
        photo: {
          alt: "Birth month flower - April, May, June",
          caption: "Birth month flower - April, May, June",
          link: birthMonthFlowers2,
          linkWebp: birthMonthFlowers2Webp,
        },
        text: "",
      },
      {
        subHeading: "Birth Month Flowers - April - Daisy & Sweet pea",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Daisy, a symbol of innocence, purity, and true love, brings a touch of sunshine and joy. Sweet pea, representing blissful pleasure and delicate beauty, adds a sweet fragrance to this special month. ",
      },
      {
        subHeading: "Birth Month Flowers - May - Lily of the valley & Hawthorn",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Lily of the valley, a symbol of sweetness, humility, and a return to happiness, fills your month with grace and joy. Hawthorn, representing hope and supreme happiness",
      },
      {
        subHeading: "Birth Month Flowers - June - Honeysuckle & Rose",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Honeysuckle, with its delightful fragrance and vibrant blooms, represents happiness and the bonds of love. Rose, a timeless symbol of love, passion, and beauty",
      },
      {
        subHeading: "",
        photo: {
          alt: "Birth month flower - July, August, September",
          caption: "Birth month flower - July, August, September",
          link: birthMonthFlowers3,
          linkWebp: birthMonthFlowers3Webp,
        },
        text: "",
      },
      {
        subHeading: "Birth Month Flowers - July - Water lily & Larkspur",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Water lily, a symbol of purity, enlightenment, and rebirth, brings a touch of tranquility and beauty to your month. Larkspur, representing positivity, grace, and an open heart",
      },
      {
        subHeading: "Birth Month Flowers - August - Poppy & Gladiolus",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Poppy, a symbol of remembrance, imagination, and eternal sleep, adds a touch of vivid beauty and depth. Gladiolus, representing strength, integrity, and infatuation",
      },
      {
        subHeading: "Birth Month Flowers - September - Morning glory & Aster",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Morning glory, a symbol of affection, love, and tenacity, brings a burst of vibrant color to your days. Aster, representing patience, love, and wisdom, adds a touch of timeless beauty and grace",
      },
      {
        subHeading: "",
        photo: {
          alt: "Birth month flower - October, November, December",
          caption: "Birth month flower - October, November, December",
          link: birthMonthFlowers4,
          linkWebp: birthMonthFlowers4Webp,
        },
        text: "",
      },
      {
        subHeading: "Birth Month Flowers - October - Marigold & Cosmos",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Marigold, symbolizing passion, creativity, and fierce love, brings a burst of vibrant energy to your days. Cosmos, representing harmony, order, and beauty",
      },
      {
        subHeading: "Birth Month Flowers - November - Chrysanthemum & Peony",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Chrysanthemum, symbolizing joy, optimism, and longevity, fills the days with vibrant hues and positive energy. Peony, representing prosperity, honor, and compassion, adds a touch of luxurious beauty",
      },
      {
        subHeading: "Birth Month Flowers - December - Holly & Narcissus",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Holly, symbolizing protection, goodwill, and eternal life, brings a touch of holiday magic to celebrations. Narcissus, representing hope, wealth, and respect, adds a touch of elegance and renewal",
      },
    ],
    cta: {
      text: "Get Birth month flower art",
      product: {
        imageUrl: birthMonthFlowersCover,
        imageUrlWebp: birthMonthFlowersCoverWebp,
        caption: "Birth month flower art",
        link: "https://bohananwoodcraft.etsy.com/listing/1710204580",
      },
    },
    conclusion:
      "Birth month flowers stand out as a timeless, meaningful, and unique way to celebrate someone special. Their rich symbolism, historical significance, and personalized touch make them a perfect gift for any occasion",
  },
  {
    cover: ringDish1Png,
    coverWebp: ringDish1Webp,
    title:
      "Elevate Your Home Décor with Handcrafted Wooden Trays: The Perfect Gift Idea for Minimalists",
    slug: "handmade-wooden-tray",
    intro:
      "In a world filled with mass-produced items, there's something truly special about incorporating handmade pieces into your home décor. Handcrafted wooden trays, in particular, offer a blend of functionality and aesthetic appeal that can elevate any space while aligning perfectly with the principles of minimalism and simplicity. Whether you're looking for a practical solution to keep your jewelry organized, a catchall tray for keys and knick-knacks, or a stylish addition to your vanity, a handmade wooden tray crafted from Cherry, Maple, or Black Walnut hardwood is the perfect choice. Not only are these trays beautiful and versatile, but they also make for an excellent gift idea for your loved ones who appreciate the beauty of simplicity.",
    paragraphs: [
      {
        subHeading: "Why Choose Handcrafted Wooden Trays?",
        photo: {
          alt: "hexegon shaped cherry wood tray",
          caption: "Hexegon Cherry wood dish",
          link: ringDishHexegon,
          linkWebp: ringDishHexegonWebp,
        },
        text: "When it comes to home décor, attention to detail matters. Handcrafted wooden trays exude a sense of authenticity and craftsmanship that simply can't be replicated by mass-produced alternatives. Each tray is carefully crafted by skilled artisans, resulting in a unique piece that adds character and warmth to any room. Additionally, wood is a durable and sustainable material, ensuring that your tray will stand the test of time while making a minimal impact on the environment.",
      },
      {
        subHeading: "Versatility at Its Finest:",
        text: "One of the greatest benefits of wooden trays is their versatility. Whether you're using them to display your favorite jewelry pieces, corral small items on your entryway table, or add a touch of elegance to your vanity, these trays can seamlessly adapt to various purposes. Their sleek and minimalist design allows them to blend effortlessly with any décor style, from modern to rustic, making them a versatile addition to any home.        ",
      },
      {
        subHeading: "Cherry, Maple, or Black Walnut: The Choice is Yours",
        photo: {
          alt: "black walnut wood heart shaped tray",
          caption: "Black walnut heart shaped",
          link: ringDishHeartWalnut,
          linkWebp: ringDishHeartWalnutWebp,
        },
        text: "When selecting a handmade wooden tray, you'll have the option to choose from three exquisite hardwoods: Cherry, Maple, or Black Walnut. Each wood species offers its own unique characteristics and beauty, ensuring that you'll find the perfect tray to suit your preferences.",
      },
      {
        subHeading: "",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "Cherry wood boasts a warm, reddish-brown hue and a smooth, fine grain, making it a timeless choice for those seeking elegance and sophistication. Maple wood, with its light, creamy color and subtle grain patterns, exudes a sense of understated charm and versatility. Meanwhile, Black Walnut wood features rich, chocolatey tones and striking grain patterns, adding a touch of drama and luxury to any space.",
      },
      {
        subHeading: "Shapes to Suit Your Style:",
        photo: {
          alt: "heart cherry wood dish",
          caption: "Heart shaped Cherry wood dish",
          link: ringDishSquared,
          linkWebp: ringDishSquaredWebp,
        },
        text: "In addition to choosing the wood type, you can also select from a variety of shapes to suit your style preferences. Whether you prefer the sleek lines of a hexagon-shaped tray, the classic simplicity of a square-shaped tray, or the whimsical charm of a heart-shaped tray, there's a shape to complement every aesthetic.",
      },
      {
        subHeading: "The Perfect Gift Idea:",
        photo: {
          alt: "",
          caption: "",
          link: "",
          linkWebp: "",
        },
        text: "With their timeless appeal and practical functionality, handmade wooden trays make for an excellent gift idea for any occasion. Whether you're celebrating a housewarming, anniversary, birthday, or holiday, a beautifully crafted wooden tray is sure to delight your friends and family members. Not only is it a thoughtful and meaningful gift, but it's also something that they can cherish and enjoy for years to come.",
      },
    ],
    cta: {
      text: "Get Handmade wood dish now",
      product: {
        imageUrl: ringDish1Png,
        imageUrlWebp: ringDish1Webp,
        caption: "Handmade wood dish",
        link: "https://bohananwoodcraft.etsy.com/listing/1524283448/free-shipping-hardwood-tray-square-heart",
      },
    },
    conclusion:
      "In a world inundated with mass-produced goods, handmade wooden trays stand out as a beacon of authenticity and craftsmanship, perfectly suited for those who embrace minimalism and simplicity. Whether you're looking to add a touch of elegance to your own home décor or searching for the perfect gift for a loved one, a handcrafted wooden tray made from Cherry, Maple, or Black Walnut hardwood is sure to impress. With their timeless beauty, versatility, and sustainability, these trays offer both style and functionality that will enhance any space, while embodying the essence of minimalism and simplicity. So why settle for ordinary when you can elevate your home with something truly extraordinary?",
  },
  {
    cover: beerOpenerMain,
    coverWebp: beerOpenerWebp,
    title:
      "Celebrates with Personalized Wooden Bottle Openers: Perfect for Parties, Weddings, and Housewarmings",
    slug: "wooden-bottle-opener",
    intro:
      "Crafted with care and attention to detail, this unique piece is more than just a functional tool—it's a personalized keepsake that adds charm to any gathering or celebration. Let's delve into why our wooden bottle opener is the perfect addition to your next party, wedding favor, or housewarming gift.",
    paragraphs: [
      {
        subHeading: "Craftsmanship and Quality:",
        text: "Our wooden bottle openers are crafted by skilled artisans using premium pine wood. Each piece is meticulously made to ensure durability and reliability, making it a long-lasting accessory for your bar or kitchen. The smooth finish and ergonomic design guarantee effortless bottle opening, while the built-in catcher keeps your space tidy and organized.",
      },
      {
        subHeading: "Personalization:",
        photo: {
          alt: "personalized lastname engrave and different stain choice",
          caption: "Personalized with your lastname and you can choose a stain",
          link: beerOpenerColorPng,
          linkWebp: beerOpenerColorWebp,
        },
        text: "What sets our wooden bottle opener apart is the option for personalization. Whether you're celebrating a wedding, housewarming, or any special occasion, engraving your last name, choosing a stain color add a personalized touch that makes it truly unique. It's the perfect way to commemorate a milestone moment or create a thoughtful gift that will be cherished for years to come.",
      },
      {
        subHeading: "Perfect for Parties and Celebrations:",

        text: "Hosting a party or celebration? Our wooden bottle opener is a must-have accessory. Say goodbye to searching for a bottle opener or dealing with stray bottle caps—our opener's built-in catcher ensures a seamless and hassle-free experience. Its rustic elegance adds to the ambiance of any event, whether it's a wedding reception, birthday party, or backyard barbecue.",
      },
      {
        subHeading: "Wedding Party Favor:",
        photo: {
          alt: "wedding party favor",
          caption: "Orders for a wedding party favor",
          link: beerOpenerWeddingPartyPng,
          linkWebp: beerOpenerWeddingPartyWebp,
        },
        text: "Looking for the perfect wedding party favor? Our personalized wooden bottle opener is sure to delight your guests. Emblazoned with your last name, it serves as a memorable keepsake that reminds guests of your special day long after the festivities have ended. Practical and stylish, it's a token of appreciation that will be treasured by all who receive it.",
      },
      {
        subHeading: "The Perfect Housewarming Gift:",
        text: "Moving into a new home is an exciting milestone, and our wooden bottle opener makes for an ideal housewarming gift. Whether it's for friends, family, or neighbors, the personalized touch adds a warm and welcoming element to their space. It's a thoughtful gesture that shows you care, while also being a practical addition to their kitchen or bar area.",
      },
    ],
    cta: {
      text: "Get Handmade bottle opener now",
      product: {
        imageUrl: beerOpenerMain,
        imageUrlWebp: beerOpenerWebp,
        caption: "Handmade wood dish",
        link: "https://bohananwoodcraft.etsy.com/listing/1519958876",
      },
    },
    conclusion:
      "It's more than just a bottle opener—it's a symbol of warmth, hospitality, and joy. Whether you're hosting a party, celebrating a wedding, or giving a housewarming gift, our wooden bottle opener is sure to make a lasting impression. Cheers to good times and memorable moments with our wooden bottle opener!",
  },
  {
    cover: cribbagePng,
    coverWebp: cribbageWebp,
    title:
      "Game Nights with Personalized Handcrafted Wooden Cribbage Game Boards",
    slug: "wooden-cribbage-game-board",
    intro:
      "Crafted with care and attention to detail, these game boards are not only a delightful addition to any game night but also make for memorable gifts for family and friends. Let's explore why our personalized wooden cribbage game boards are perfect for home parties, game enthusiasts, and anyone looking for a unique gift idea.",
    paragraphs: [
      {
        subHeading: "Personalization:",
        text: "What sets our cribbage game boards apart is the option for personalization. Imagine having your last name elegantly carved onto the wooden surface, adding a custom touch that makes each board truly unique. Whether you're buying it for yourself or as a gift for a loved one, the personalized aspect adds sentimental value and makes it a cherished keepsake for years to come.",
      },
      {
        subHeading: "Perfect for Home Parties and Game Nights:",
        photo: {
          alt: "pegs storage cribbage",
          caption: "You can storage pegs at the back of the cribbage board",
          link: cribbageBackPng,
          linkWebp: cribbageBackWebp,
        },
        text: "Hosting a home party or game night with family and friends? Our wooden cribbage game boards are sure to be a hit. Cribbage is a beloved card game that brings people together, and playing on a beautifully crafted board adds to the experience. Whether you're a seasoned cribbage player or new to the game, our boards provide the perfect backdrop for hours of fun and friendly competition.",
      },
      {
        subHeading: "Ideal Gift for Cribbage Game Lovers:",
        photo: {
          alt: "handmade wooden cribbage board",
          caption: "Handmade wooden cribbage board",
          link: cribbageDetailPng,
          linkWebp: cribbageDetailWebp,
        },
        text: "Searching for the perfect gift for the game lover in your life? Look no further than our personalized wooden cribbage game boards. Whether it's for a birthday, holiday, or special occasion, these boards are sure to impress. The combination of craftsmanship, personalization, and timeless appeal makes them a gift that will be treasured by any cribbage enthusiast.",
      },
      {
        subHeading: "Choose Your Wood:",
        photo: {
          alt: "cribbage board in walnut",
          caption: "cribbage board in walnut",
          link: cribbageWalnutPng,
          linkWebp: cribbageWalnutWebp,
        },
        text: "Our cribbage game boards are available in three types of hardwood: cherry, maple, and black walnut. Each wood has its own unique characteristics, from the warm tones of cherry to the light grain of maple and the rich hues of black walnut. Whether you prefer a classic look or something more modern, there's a wood option to suit your taste and style.",
      },
    ],
    cta: {
      text: "Get Handmade cribbage board now",
      product: {
        imageUrl: cribbagePng,
        imageUrlWebp: cribbageWebp,
        caption: "Handmade wood dish",
        link: "https://bohananwoodcraft.etsy.com/listing/1648536879/personalized-handmade-wooden-cribbage",
      },
    },
    conclusion:
      "It's more than just a bottle opener—it's a symbol of warmth, hospitality, and joy. Whether you're hosting a party, celebrating a wedding, or giving a housewarming gift, our wooden bottle opener is sure to make a lasting impression. Cheers to good times and memorable moments with our wooden bottle opener!",
  },
  {
    cover: soapDishMainPng,
    coverWebp: soapDishMainWebp,
    title: "Bathroom Decor with Handcrafted Cedar Soap Dishes",
    slug: "handmade-cedar-soap-dish",
    intro:
      "These soap dishes are not only functional but also add a touch of natural elegance to any bathroom. Let's explore why our cedar soap dishes are the perfect choice for styling your bathroom and enhancing your daily routine.",
    paragraphs: [
      {
        subHeading: "Advantages of Cedar Wood:",
        text: "Cedar wood is renowned for its natural durability and rot resistance, making it an ideal choice for bathroom accessories like soap dishes. Unlike other types of wood, cedar is naturally resistant to moisture, mold, and mildew, ensuring that your soap dish stays in pristine condition for years to come. Its aromatic scent adds a refreshing fragrance to your bathroom, enhancing the overall ambiance of the space.",
        review: {
          text: "It's so pretty and exceptional quality, the cedar smells great, love the design. Purchased as a housewarming gift. I will probably order again in the future!",
          customerInitial: "G.T.B - Dec 2023",
        },
      },
      {
        subHeading: "Perfect for Styling Your Bathroom:",
        text: "Our cedar soap dishes are available in four different designs, each carefully crafted to complement a variety of bathroom styles. Whether you prefer a minimalist look or something more intricate, there's a design to suit your taste and aesthetic preferences. From simple, sleek lines to ornate patterns, our soap dishes add a touch of rustic charm to any bathroom decor.",
        review: {
          photo: {
            alt: "customer review for Bohanan woodcraft soap dish",
            caption: "Customer review for Bohanan woodcraft soap dish",
            link: soapDishReviewPng,
            linkWebp: soapDishReviewWebp,
          },
          text: "Got it for a gift and it came better then expected with the cedar tree smell and a handwritten thank you note. It works great and you can tell that someone had put their time into making it",
          customerInitial: "H.H - Feb 2024",
        },
      },
      {
        subHeading: "Functional and Practical:",
        photo: {
          alt: "best selling soap dish design",
          caption: "Our best-selling soap dish design",
          link: soapDishFavouritePng,
          linkWebp: soapDishFavouriteWebp,
        },
        text: "In addition to their stylish appearance, our cedar soap dishes are designed with functionality in mind. The slatted design allows for proper drainage, ensuring that your soap stays dry between uses and lasts longer. This not only helps to prevent soap scum buildup but also promotes better hygiene in your bathroom. Say goodbye to soggy soap and hello to a cleaner, more organized space.",
      },
      {
        subHeading: "Enhance Your Daily Routine:",

        text: "Using a cedar soap dish not only adds beauty to your bathroom but also enhances your daily routine. The natural properties of cedar wood help to preserve the quality of your soap, keeping it fresh and fragrant with each use. Its smooth surface is gentle on your soap and won't cause it to stick or become misshapen over time. With our cedar soap dishes, you can elevate the simple act of washing your hands or taking a shower into a luxurious experience.        ",
      },
    ],
    cta: {
      text: "Get Handmade cedar soap dish now",
      product: {
        imageUrl: soapDishMainPng,
        imageUrlWebp: soapDishMainWebp,
        caption: "Handmade cedar soap dish",
        link: "https://bohananwoodcraft.etsy.com/listing/1543077869/free-shipping-wood-soap-dish-handcrafted",
      },
    },
    conclusion:
      "It's more than just a bottle opener—it's a symbol of warmth, hospitality, and joy. Whether you're hosting a party, celebrating a wedding, or giving a housewarming gift, our wooden bottle opener is sure to make a lasting impression. Cheers to good times and memorable moments with our wooden bottle opener!",
  },
];

export default posts;
