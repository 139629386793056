import { useState, useEffect } from "react";
import { Helmet } from "react-helmet";

import heroProduct from "../../assets/images/soap-dish-hero.png";
import heroProductWebp from "../../assets/images/soap-dish-hero.webp";
import productBanner from "../../assets/images/bohanan-woodcraft-hero.png";
import productBannerWebp from "../../assets/images/bohanan-woodcraft-hero.webp";

import breadBowKnifeProduct from "../../assets/images/bread-bow-knife.png";
import breadBowKnifeProductWebp from "../../assets/images/bread-bow-knife.webp";
import breadBowKnifeProductHover from "../../assets/images/bread-bow-knife-hover.png";
import breadBowKnifeProductHoverWebp from "../../assets/images/bread-bow-knife-hover.webp";

import cribbageMain from "../../assets/images/wood-cribbage.png";
import cribbageMainWebp from "../../assets/images/wood-cribbage.webp";
import cribbageHover from "../../assets/images/wood-cribbage-hover.png";
import cribbageHoverWebp from "../../assets/images/wood-cribbage-hover.webp";

import soapDishHover from "../../assets/images/IMG_1703.png";
import soapDishHoverWebp from "../../assets/images/IMG_1703.webp";

import blade from "../../assets/images/blade.png";
import bladeSet from "../../assets/images/blade set.png";
import bladeWebp from "../../assets/images/blade.webp";
import bladeSetWebp from "../../assets/images/blade set.webp";

import bottleOpener from "../../assets/images/bottle opener.png";
import bottleOpenerHover from "../../assets/images/bottle opener hover.png";
import bottleOpenerWebp from "../../assets/images/bottle opener.webp";
import bottleOpenerHoverWebp from "../../assets/images/bottle opener hover.webp";

import whiskeyTray from "../../assets/images/whiskey tray.jpg";
import whiskeyTrayHover from "../../assets/images/whiskey tray hover.jpg";
import whiskeyTrayWebp from "../../assets/images/whiskey-tray.webp";
import whiskeyTrayHoverWebp from "../../assets/images/whiskey-tray-hover.webp";

import ImageHover from "../../components/image-hover";
import getReviews, { Review } from "../../utils/getReviews";
import ReviewDiv from "../../components/review-div";

import postData from "../blog/post/data/data";
import { Blog } from "../blog/post/constants";
import BlogThumbnail from "../../components/blog-thumbnail";
import { Link } from "react-router-dom";
import Header from "../../components/header";

function Home() {
  const [reviews, setReviews] = useState<Review[]>([]);
  useEffect(() => {
    const data = getReviews();
    setReviews(data);
  }, []);
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bohanan Woodcraft - Handmade wood home deco in Illinois</title>
        <link rel="canonical" href={`https://bohananwoodcraft.com`} />
        <meta
          name="description"
          content={`Bohanan Woodcraft is a small business based in Illinois. We specialize in handmade woodcraft, including kitchen ware, home decor, wood art. Our signature product is Bread bow knife and Cedar soap dish. We are also Etsy Star Seller.`}
        ></meta>
      </Helmet>
      <Header />
      <div className="hero">
        <div className="hero__image">
          <picture>
            <source srcSet={heroProductWebp} type="image/webp" />
            <source srcSet={heroProduct} type="image/jpeg" />
            <img src={heroProduct} alt="bohanan woodcraft" />
          </picture>
        </div>
        <div className="hero__content">
          <h2>Handmade</h2>
          <h2>Home Deco</h2>
          <h2>Home Furnishing</h2>
        </div>
      </div>
      <div className="product">
        <picture>
          <source srcSet={productBannerWebp} type="image/webp" />
          <source srcSet={productBanner} type="image/png" />
          <img src={productBanner} alt="bohanan woodcraft" />
        </picture>

        <div className="product__content">
          <h3>Featured Products</h3>
          <a
            className="product__item"
            href="/products/wood-bread-bow-knife"
            target="_blank"
            rel="noreferrer"
          >
            <ImageHover
              mainImage={breadBowKnifeProduct}
              mainImageWebp={breadBowKnifeProductWebp}
              hoverImage={breadBowKnifeProductHover}
              hoverImageWebp={breadBowKnifeProductHoverWebp}
              alt="bread bow knife"
            />
            <h4>Bread bow knife</h4>
            <h5>Sold 660+ in last two months</h5>
            <h5>Perfect gift!</h5>
          </a>
          <a
            className="product__item"
            href="/products/wood-personalized-cribbage-board"
            target="_blank"
            rel="noreferrer"
          >
            <div className="product__item__image">
              <ImageHover
                mainImage={cribbageMain}
                mainImageWebp={cribbageMainWebp}
                hoverImage={cribbageHover}
                hoverImageWebp={cribbageHoverWebp}
                alt="Personalized cribbage board"
              />
            </div>
            <h4>Personalized cribbage board</h4>
            <h5>Personalized gift for card game lover!</h5>
          </a>
          <a
            className="product__item"
            href="/products/cedar-soap-dish"
            target="_blank"
            rel="noreferrer"
          >
            <div className="product__item__image">
              <ImageHover
                mainImage={heroProduct}
                mainImageWebp={heroProductWebp}
                hoverImage={soapDishHover}
                hoverImageWebp={soapDishHoverWebp}
                alt="cedar soap dish"
              />
            </div>
            <h4>Cedar Soap dish</h4>
            <h5>Durable | Stylish</h5>
          </a>
          <a
            className="product__item"
            href="https://bohananwoodcraft.etsy.com/listing/1571938295/free-shipping-for-2-orders-bread-bow"
            target="_blank"
            rel="noreferrer"
          >
            <div className="product__item__image">
              <ImageHover
                mainImage={blade}
                hoverImage={bladeSet}
                mainImageWebp={bladeWebp}
                hoverImageWebp={bladeSetWebp}
                alt="Bread bow knife blade replacement"
              />
            </div>
            <h4>Bread bow knife blade</h4>
            <h5>Perfect for your woodworking project!</h5>
          </a>
          <a
            className="product__item"
            href="https://bohananwoodcraft.etsy.com/listing/1519958876/personalized-wood-bottle-opener-cap"
            target="_blank"
            rel="noreferrer"
          >
            <div className="product__item__image">
              <ImageHover
                mainImage={bottleOpener}
                hoverImage={bottleOpenerHover}
                mainImageWebp={bottleOpenerWebp}
                hoverImageWebp={bottleOpenerHoverWebp}
                alt="Bottle opener"
              />
            </div>
            <h4>Bottle Opener</h4>
            <h5>Perfect for party! Wedding favor!</h5>
          </a>

          <a
            className="product__item"
            href="https://bohananwoodcraft.etsy.com/listing/1534131357/personalized-whiskey-serving-tray-house"
            target="_blank"
            rel="noreferrer"
          >
            <div className="product__item__image">
              <ImageHover
                mainImage={whiskeyTray}
                hoverImage={whiskeyTrayHover}
                mainImageWebp={whiskeyTrayWebp}
                hoverImageWebp={whiskeyTrayHoverWebp}
                alt="whiskey tray"
              />
            </div>
            <h4>Whiskey Tray</h4>
            <h5>Gift for whiskey lover!</h5>
          </a>
          <a
            className="product__store-link"
            href="https://bohananwoodcraft.etsy.com"
            target="_blank"
            rel="noreferrer"
          >
            View all products
          </a>
        </div>
      </div>
      <div className="blog">
        <div className="blog__content">
          <h3>Blogs</h3>
          <div className="blog__content__main">
            {postData.slice(0, 3).map((b: Blog) => (
              <BlogThumbnail blog={b} key={b.slug} />
            ))}
          </div>
          <Link className="blog__content__viewAll" to={"/blogs"}>
            View all updates
          </Link>
        </div>
      </div>
      <div className="review">
        <div className="review__content">
          <h3>Customer Reviews</h3>
          <div className="review__content__main">
            {reviews.map((review: Review) => (
              <ReviewDiv key={review.id} review={review} />
            ))}
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
