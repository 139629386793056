import { FunctionComponent } from "react";
import { Review } from "../../utils/getReviews";

interface ReviewDivProps {
  review: Review;
}

const ReviewDiv: FunctionComponent<ReviewDivProps> = ({
  review,
  ...props
}: ReviewDivProps) => {
  return (
    <div className="review-item" {...props}>
      <p>{review.text}</p>
    </div>
  );
};

export default ReviewDiv;
