import styled from "styled-components";

export const LocationPage = styled.div`
  padding: 40px 0;
  font-size: 24px;
  line-height: 1.5;
  h3 {
    font-size: 36px;
    margin-bottom: 20px;
  }
`;

export const LocationPageShopImage = styled.div`
  width: 100%;
  max-width: 600px;
  height: 300px;
  background: #f1f1f1;
  margin: 16px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    text-align: center;
    font-size: 30px;
  }
  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
`;

export const LocationSection = styled.div`
  padding: 20px 0;
  margin: 20px 0;
  border-top: 1px solid lightgray;
  h4{
    font-size 24px;
    font-weight: 700;
  }
  h5{
    font-size: 24px;
    font-weight: 600;
  }
`;
