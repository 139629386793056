import { FunctionComponent, useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import * as Styled from "./styles";

import posts from "./data/data";
import { Blog } from "./constants";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import BlogThumbnail from "../../../components/blog-thumbnail";
import Header from "../../../components/header";
import post from ".";

const Post: FunctionComponent = () => {
  const params = useParams();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [currentPost, setCurrentPost] = useState<Blog>();
  const [relatedPost, setRelatedPost] = useState<Blog[]>([]);

  useEffect(() => {
    if (params.blogSlug) {
      const blog = posts.filter(
        (p) => p.slug.toLowerCase() === params.blogSlug?.toLowerCase()
      )[0];
      const relatedPosts = posts.filter(
        (p) => p.slug.toLowerCase() !== params.blogSlug?.toLowerCase()
      );
      if (blog) {
        setCurrentPost(blog);
        setRelatedPost(relatedPosts);
      }
      setIsLoading(false);
    }
  }, [params]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, [currentPost]);

  if (isLoading) return <></>;
  if (!currentPost) return <></>;
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{currentPost.title}</title>
        <link
          rel="canonical"
          href={`https://bohananwoodcraft.com/blogs/${currentPost.slug}`}
        />
        <meta name="description" content={currentPost.intro}></meta>
      </Helmet>
      <Header />
      <Styled.PostPage>
        <Link className="backHome" to={"/blogs"}>
          {`< `}Home
        </Link>
        <Styled.PostHeader>
          <Styled.PostCover>
            <picture>
              <source srcSet={currentPost.coverWebp} type="image/webp" />
              <source srcSet={currentPost.cover} type="image/png" />
              <img src={currentPost.cover} alt={currentPost.title} />
            </picture>
          </Styled.PostCover>
          <h1>{currentPost.title}</h1>
        </Styled.PostHeader>

        <p className="intro">{currentPost.intro}</p>
        {currentPost.paragraphs.map((p, index) => (
          <Styled.PostParagraph key={`${currentPost.slug}-${index}`}>
            <h2>{p.subHeading}</h2>
            <p>{p.text}</p>
            {p.review && (
              <Styled.PostReviewDiv>
                <p className="customer-review-heading">Customer review:</p>
                {p.review.photo && (
                  <Styled.PostParagraphPhoto>
                    <picture>
                      <source
                        srcSet={p.review.photo.linkWebp}
                        type="image/webp"
                      />
                      <source srcSet={p.review.photo.link} type="image/png" />
                      <img src={p.review.photo.link} alt={p.review.photo.alt} />
                    </picture>
                  </Styled.PostParagraphPhoto>
                )}
                <p className="customer-review-main">{p.review.text}</p>
                <p className="customer-review-name">
                  <i>{p.review.customerInitial}</i>
                </p>
              </Styled.PostReviewDiv>
            )}
            {p.photo?.link && (
              <Styled.PostParagraphPhoto>
                <picture>
                  <source srcSet={p.photo.linkWebp} type="image/webp" />
                  <source srcSet={p.photo.link} type="image/png" />
                  <img src={p.photo.link} alt={p.photo.alt} />
                </picture>
                {p.photo.caption && <span>{p.photo.caption}</span>}
              </Styled.PostParagraphPhoto>
            )}
          </Styled.PostParagraph>
        ))}
        <Styled.PostCTA>
          <Styled.PostCTAButton
            href={currentPost.cta.product.link}
            target="_blank"
          >
            <picture>
              <source
                srcSet={currentPost.cta.product.imageUrlWebp}
                type="image/webp"
              />
              <source
                srcSet={currentPost.cta.product.imageUrl}
                type="image/png"
              />
              <img
                src={currentPost.cta.product.imageUrl}
                alt={currentPost.cta.product.caption}
              />
            </picture>
            <p>{currentPost.cta.text}</p>
          </Styled.PostCTAButton>
        </Styled.PostCTA>
        <Styled.RelatedPostDiv>
          <h2>Read other blogs</h2>
          {relatedPost.map((b) => (
            <BlogThumbnail blog={b} key={b.slug} />
          ))}
        </Styled.RelatedPostDiv>
      </Styled.PostPage>
    </>
  );
};

export default Post;
