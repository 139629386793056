import { Link } from "react-router-dom";

const Header = () => {
  return (
    <div className="header">
      <Link to={"/"}>
        <h1> BOHANAN WOODCRAFT</h1>
      </Link>
      <Link to={"/location"} className="location-link">
        Location
      </Link>
    </div>
  );
};

export default Header;
