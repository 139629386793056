import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/home";
import Product from "./pages/product";
import BlogHome from "./pages/blog/home";
import bohananWoodcraftLogo from "./assets/images/bohanan-woodcraft-logo.png";
import Post from "./pages/blog/post";
import CareInstructions from "./pages/care-instructions";
import LocationPage from "./pages/location";

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path="/" element={<Home />} />
      <Route path="/products/:productName" element={<Product />} />
      <Route path="/blogs" element={<BlogHome />} />
      <Route path="/blogs/:blogSlug" element={<Post />} />
      <Route
        path="/care-instructions/:productName"
        element={<CareInstructions />}
      />
      <Route path="/location" element={<LocationPage />} />
    </>
  )
);

function App() {
  return (
    <div className="App">
      <div className="container">
        <RouterProvider router={router} />
        <div className="footer">
          <img src={bohananWoodcraftLogo} alt="bohanan woodcraft" />
          <p>Bohanan Woodcraft LLC. Established 2022.08</p>
        </div>
      </div>
    </div>
  );
}

export default App;
