import styled from "styled-components";

export const BlogPage = styled.div`
  min-height: 100vh;
  padding: 40px 0;
  .backHome {
    display: block;
    font-size: 24px;
    margin-bottom: 20px;
  }
`;

export const BlogPageMain = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
