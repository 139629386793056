import { FunctionComponent } from "react";

import * as Styled from "./styles";
import { Blog } from "../../pages/blog/post/constants";

interface BlogThumbnailProps {
  blog: Blog;
}

const BlogThumbnail: FunctionComponent<BlogThumbnailProps> = ({
  blog,
}: BlogThumbnailProps) => {
  return (
    <Styled.BlogThumbnailDiv to={`/blogs/${blog.slug}`}>
      <Styled.BlogThumbnailImgDiv>
        <img src={blog.cover} alt={blog.title} />
      </Styled.BlogThumbnailImgDiv>

      <Styled.BlogThumbnailTitle>{blog.title}</Styled.BlogThumbnailTitle>
    </Styled.BlogThumbnailDiv>
  );
};

export default BlogThumbnail;
