import breadBowKnifeMain from "../../assets/images/bread-bow-knife.png";
import breadBowKnifeMainWebp from "../../assets/images/bread-bow-knife.webp";
import breadBowKnifeHover from "../../assets/images/bread-bow-knife-hover.png";
import breadBowKnifeHoverWebp from "../../assets/images/bread-bow-knife-hover.webp";

import soapDishMain from "../../assets/images/soap-dish-hero.png";
import soapDishMainWebp from "../../assets/images/soap-dish-hero.webp";
import soapDishHover from "../../assets/images/IMG_1703.png";
import soapDishHoverWebp from "../../assets/images/IMG_1703.webp";

import cribbageMain from "../../assets/images/wood-cribbage.png";
import cribbageMainWebp from "../../assets/images/wood-cribbage.webp";
import cribbageHover from "../../assets/images/wood-cribbage-hover.png";
import cribbageHoverWebp from "../../assets/images/wood-cribbage-hover.webp";

export type ImageData = {
  main: string;
  mainWebp: string;
  hover: string;
  hoverWebp: string;
};

export type Product = {
  productTitle: string;
  productTitleLong: string;
  image: ImageData;
  description: string[];
  purchaseLink: string;
};
export type ProductData = {
  [productSlug: string]: Product;
};

const productData: ProductData = {
  "wood-personalized-cribbage-board": {
    productTitle: "Personalized Handmade Wood Cribbage board",
    productTitleLong: "Personalized Handmade Wood Cribbage board",
    image: {
      main: cribbageMain,
      mainWebp: cribbageMainWebp,
      hover: cribbageHover,
      hoverWebp: cribbageHoverWebp,
    },
    description: [
      "Personalized Gift for friends and family who love cribbage",
      "Personalized Gift for significant others",
      "Are you a game lover?",
      "Free shipping within the USA",
      "Proudly handmade in the USA",
    ],
    purchaseLink:
      "https://bohananwoodcraft.etsy.com/listing/1648536879/personalized-handmade-wooden-cribbage",
  },
  "wood-bread-bow-knife": {
    productTitle: "Wood Bread Bow Knife",
    productTitleLong:
      "Handmade Wood Bread Bow Knife | Perfect for Hard bread cutting | Sourdough | Baguette",
    image: {
      main: breadBowKnifeMain,
      mainWebp: breadBowKnifeMainWebp,
      hover: breadBowKnifeHover,
      hoverWebp: breadBowKnifeHoverWebp,
    },
    description: [
      "Gift for friends and family who start their bread-making journey?",
      "Gift for significant others",
      "Are you a bread lover?",
      "Are you a sourdough lover?",
      "Are you a baguette lover?",
      "Try out this effortless hard crust bread cutting knife",
      "Sold over 660+ in the past two months",
      "Free shipping within the USA",
      "Proudly handmade in the USA",
    ],
    purchaseLink:
      "https://bohananwoodcraft.etsy.com/listing/1529193522/free-shipping-wood-bread-bow-knife",
  },
  "cedar-soap-dish": {
    productTitle: "Handmade Cedar Soap Dish | Durable",
    productTitleLong: "Handmade Cedar Soap Dish | Durable | Rot-resistance",
    image: {
      main: soapDishMain,
      mainWebp: soapDishMainWebp,
      hover: soapDishHover,
      hoverWebp: soapDishHoverWebp,
    },
    description: [
      "Handmade with Cedar wood",
      "Crafted from high-quality, sustainably sourced cedar wood",
      "Adds a touch of natural elegance to your bathroom",
      "Simple gift for friends and family",
      "Gift under $15",
      "Free shipping within the USA",
      "Proudly handmade in the USA",
      "Free shipping",
    ],
    purchaseLink:
      "https://bohananwoodcraft.etsy.com/listing/1543077869/free-shipping-wood-soap-dish-handcrafted",
  },
};

export default productData;
