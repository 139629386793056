import { FunctionComponent } from "react";
import postData from "../post/data/data";

import * as Styled from "./styles";
import { Helmet } from "react-helmet";
import BlogThumbnail from "../../../components/blog-thumbnail";
import Header from "../../../components/header";
import { Link } from "react-router-dom";

const Blog: FunctionComponent = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Bohanan Woodcraft - Updates</title>
        <link rel="canonical" href={`https://bohananwoodcraft.com/blogs`} />
        <meta
          name="description"
          content={`Bohanan Woodcraft is a small business based in Illinois. We specialize in handmade woodcraft, including kitchen ware, home decor, wood art. Our signature product is Bread bow knife and Cedar soap dish. We are also Etsy Star Seller.`}
        ></meta>
      </Helmet>
      <Header />
      <Styled.BlogPage>
        <Link className="backHome" to={"/"}>
          {`< `}Home
        </Link>
        <h1>Bohanan Woodcraft - Updates</h1>
        <Styled.BlogPageMain>
          {postData.map((b) => (
            <BlogThumbnail blog={b} key={b.slug} />
          ))}
        </Styled.BlogPageMain>
      </Styled.BlogPage>
    </>
  );
};

export default Blog;
